const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"roboto-condensed",serif',
  primaryColor: '#b25416',
  fontColor: '#454949',
  bgColor: '#fff',
  brownColor:'#b25416',
  blueColor:'#5271ff',
  ...overloading
}

export default variables;